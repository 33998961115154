import { Box, Flex, Image, Text, HStack, Button, VStack, useToast, SimpleGrid, Center } from "@chakra-ui/react";
import { usePlayback } from "../../../provider/playback";
import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/nextjs";
import { secondsToTimeString } from "../../../utils/helper";
import { PauseIcon, PhotographIcon, PlayIcon, XCircleIcon } from "@heroicons/react/solid";
import VideoProgressBar from "./video-progress-bar";

export default function PlaybackBar() {
  const { pause, stop, currentTime, currentContent, duration, isPlaying, isPaused } = usePlayback();

  const toast = useToast();

  const [contentIsPlaying, setContentIsPlaying] = useState(true);

  useEffect(() => {
    if (currentTime >= duration && duration > 0) {
      stop()
        .then(res => {
          toast({
            status: "info",
            title: "Video vollständig abgespielt",
            description: "Aktive Wiedergabe wird geschlossen.",
            isClosable: true
          });
        })
        .catch(err => {
          Sentry.captureException(err);
        });
    }
  }, []);

  const getContentName = () => {
    if (currentContent) {
      return currentContent.title;
    }
    return "Unbekannt";
  };

  const togglePlayback = () => {
    setContentIsPlaying(!contentIsPlaying);
    pause()
      .then(() => {})
      .catch(err => {
        Sentry.captureException(err);
      });
  };

  const stopPlayback = () => {
    setContentIsPlaying(false);
    stop()
      .then(() => {})
      .catch(err => {
        Sentry.captureException(err);
      });
  };

  if (!isPlaying) {
    return null;
  }

  const renderPreviewFile = () => {
    let previewFile = "";
    if (currentContent?.contentPreviewFile && currentContent?.contentPreviewFile.length > 0) {
      previewFile = currentContent?.contentPreviewFile[0]?.file;
    } else if (currentContent?.previewFiles && currentContent?.previewFiles.length > 0) {
      previewFile = currentContent?.previewFiles[0]?.file;
    }

    if (previewFile) {
      return (
        <Image maxH={"100px"} maxW={"250px"} src={previewFile} alt={"Vorschaubild"} draggable={"false"} borderRadius={"md"} />
      );
    } else {
      return (
        <Box maxH={"100px"} maxW={"250px"} backgroundColor={"gray.50"} borderRadius={"md"}>
          <Box width={"full"}>
            <Center color={"darkBlue.500"}>
              <PhotographIcon className={"w-1/2 h-auto object-center m-auto"} />
            </Center>
          </Box>
        </Box>
      );
    }
  };

  return (
    <Box position={"fixed"} bottom={"0"} left={"0"} right={"0"} bg={"white"} shadow={"2xl"} zIndex={10}>
      <SimpleGrid columns={3} spacing={"4"}>
        <Box h={"full"} w={"full"}>
          <Flex h={"full"} alignItems={"center"} justifyContent={"start"} p={"4"}>
            <HStack spacing={"3"} h={"full"}>
              {renderPreviewFile()}

              <VStack
                spacing={"1"}
                w={"full"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"start"}
                alignItems={"flex-start"}
              >
                <Text maxW={"100%"} textAlign={"start"} fontSize={"sm"}>
                  {isPaused ? "Pausiert" : "Wird abgespielt"}
                </Text>

                <Text maxW={"100%"} textAlign={"start"} fontSize={"md"} fontWeight={600}>
                  {getContentName()}
                </Text>
              </VStack>
            </HStack>
          </Flex>
        </Box>
        <Box h={"full"} w={"full"}>
          <Flex h={"full"} w={"full"} alignItems={"center"} justifyContent={"center"} p={"4"}>
            <VStack w={"full"}>
              {currentContent?.contentType === "video" && (
                <>
                  <HStack spacing={"3"} h={"full"}>
                    <Button
                      size={"sm"}
                      isDisabled={contentIsPlaying}
                      leftIcon={<PlayIcon className={"h-5 w-5 text-white"} />}
                      onClick={togglePlayback}
                    >
                      Starten
                    </Button>
                    <Button
                      size={"sm"}
                      isDisabled={!contentIsPlaying}
                      leftIcon={<PauseIcon className={"h-5 w-5 text-white"} />}
                      onClick={togglePlayback}
                    >
                      Pausieren
                    </Button>
                  </HStack>
                  <Flex flexDir={"column"} alignContent={"center"} justify={"center"}>
                    <Text textAlign={"center"}>Laufzeit:</Text>
                    <Text fontSize={"md"} fontWeight={600} textAlign={"center"}>
                      {secondsToTimeString(currentTime)}
                    </Text>
                  </Flex>

                  <VideoProgressBar currentTime={currentTime} duration={duration}></VideoProgressBar>
                </>
              )}
            </VStack>
          </Flex>
        </Box>
        <Box h={"full"} w={"full"}>
          <Flex h={"full"} alignItems={"center"} justifyContent={"end"} p={"4"}>
            <Button mr={"2"} size={"sm"} rightIcon={<XCircleIcon className={"h-5 w-5"} />} onClick={stopPlayback}>
              Wiedergabe beenden
            </Button>
          </Flex>
        </Box>
      </SimpleGrid>
    </Box>
  );
}
