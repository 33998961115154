import React from "react";
import { Button, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { RiNumbersFill } from "react-icons/ri";
import { ResponsiveButtonIcon } from "../responsiveButtonIcon/responsiveButtonIcon";

interface IPaginationProps {
  currentPage: number;
  totalPages?: number;
  entityLimitPerPage?: number;
  setEntityLimitPerPage?: (limit: number) => void;
  onPageChange: (newPageIndex: number) => void;
}

interface IPerPageLimitOption {
  value: number;
  label: string;
}

const enitityPerPageOptions: IPerPageLimitOption[] = [
  { label: "24", value: 24 },
  { label: "48", value: 48 },
  { label: "96", value: 96 }
];

const Pagination = ({
  currentPage,
  entityLimitPerPage,
  setEntityLimitPerPage,
  totalPages = 0,
  onPageChange
}: IPaginationProps) => {
  if (totalPages <= 1) return null; // Hide pagination if only one or no pages

  /**
   * We need to find the sliceStartIndex to display the correct page numbers ( 3 elements )
   */
  let sliceStartIndex =
    totalPages - currentPage == 0 ? currentPage - 3 : totalPages - currentPage == 1 ? currentPage - 2 : currentPage - 1;
  if (sliceStartIndex === -1) {
    sliceStartIndex = 0;
  }

  const pageNumbers = [...Array(totalPages).keys()].map(num => num + 1);

  return (
    <Flex gap={2} mt={4} w="full" justifyContent={"center"}>
      <Flex gap={2}>
        <Button
          size={["sm", "sm", "md"]}
          isDisabled={currentPage === 1}
          borderRadius={"3xl"}
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <ArrowBackIcon />
        </Button>

        {currentPage > 1 && (
          <>
            {totalPages > 3 && currentPage > 1 && (
              <Tooltip label="Zu ersten Seite">
                <Button
                  size={["sm", "sm", "md"]}
                  variant="outline"
                  borderRadius={"3xl"}
                  onClick={() => onPageChange(1)}
                  disabled={currentPage === totalPages}
                >
                  1
                </Button>
              </Tooltip>
            )}
          </>
        )}
        {/**
         * only show the current and the next / previous 2 elements
         */}
        {pageNumbers.slice(sliceStartIndex, currentPage + 2).map(num => (
          <Tooltip label={`Seite ${num}`} key={num}>
            <Button
              size={["sm", "sm", "md"]}
              borderRadius={"3xl"}
              onClick={() => onPageChange(num)}
              _active={{ bgColor: "lightBlue.500" }}
              isActive={num === currentPage}
            >
              {num}
            </Button>
          </Tooltip>
        ))}
        {currentPage < totalPages && totalPages - currentPage >= 3 && (
          <>
            {totalPages > 3 && (
              <Tooltip label="Zur letzten Seite">
                <Button
                  size={["sm", "sm", "md"]}
                  borderRadius={"3xl"}
                  variant="outline"
                  onClick={() => onPageChange(totalPages)}
                  disabled={currentPage === totalPages}
                >
                  {totalPages}
                </Button>
              </Tooltip>
            )}
          </>
        )}
        <Button
          size={["sm", "sm", "md"]}
          isDisabled={currentPage === totalPages}
          borderRadius={"3xl"}
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <ArrowForwardIcon />
        </Button>
      </Flex>
      {typeof setEntityLimitPerPage != "undefined" && (
        <Flex alignSelf={"flex-end"} gap={4} alignItems={"center"}>
          <Menu>
            <MenuButton
              as={IconButton}
              size={["sm", "sm", "md"]}
              icon={<ResponsiveButtonIcon icon={RiNumbersFill} />}
              width={"min-content"}
              _active={{ bgColor: "lightBlue.100" }}
              aria-label="Options"
            ></MenuButton>
            <MenuList zIndex={3}>
              {enitityPerPageOptions.map(option => {
                return (
                  <MenuItem
                    key={option.value}
                    _hover={{ bgColor: "lightBlue.100" }}
                    onClick={() => (setEntityLimitPerPage ? setEntityLimitPerPage(option.value) : () => {})}
                    bgColor={entityLimitPerPage === option.value ? "lightBlue.200" : "unset"}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
          <Text display={["none", "none", "inherit"]} color={"darkBlue.500"} fontWeight={600}>
            {entityLimitPerPage} Einträge pro Seite
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default Pagination;
