import { AxiosResponse, AxiosRequestHeaders, AxiosPromise } from "axios";

import { IContentCategory } from "../../models/contentCategory";
import { ILibraryContent } from "../../models/content";
import { dispatch } from "./http";
import { content } from "../../../tailwind.config";

export type PreSignedUlrResponse = {
  url: string;
  uuid: string;
};

export interface ShareContentDTO {
  contentId: number;
  organisationIds: number[];
}
export interface RemoveShareContentDTO {
  contentId: number;
  organisationIds: number[];
}

export class ApiLibraryContentService {
  public static fetchAllContent(pageParam: URLSearchParams): Promise<AxiosResponse> {
    // Set sorting parameters
    pageParam.set("ordering", "-created_at"); // Field to order by

    // Dispatch request
    return dispatch({
      method: "GET",
      url: "/api/v2/cms/content/",
      params: pageParam // Axios will append these to the URL as query parameters
    });
  }

  public static fetchAllMSBContent(pageParam: URLSearchParams): Promise<AxiosResponse> {
    pageParam.set("ordering", "title");
    return dispatch({
      method: "GET",
      url: "/api/v2/cms/content/",
      params: pageParam
    });
  }

  public static fetchSingleContent(id: number): Promise<AxiosResponse<ILibraryContent>> {
    return dispatch({
      method: "GET",
      url: `/api/v2/cms/content/${id}/`
    });
  }
  public static fetchSingleMSBContent(id: number): Promise<AxiosResponse<ILibraryContent>> {
    return dispatch({
      method: "GET",
      url: `/api/v2/cms/content/${id}/`
    });
  }

  public static updateContent(id: number, content: ILibraryContent): Promise<AxiosResponse> {
    return dispatch({
      method: "PATCH",
      url: `/api/v1/cms/content/${id}/`,
      data: content
    });
  }

  public static createContent(content: ILibraryContent): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: "/api/v1/cms/content/",
      data: content
    });
  }

  public static deleteContent(id: any): Promise<AxiosResponse> {
    return dispatch({
      method: "DELETE",
      url: `/api/v1/cms/content/${id}/`
    });
  }

  public static shareContent(dto: ShareContentDTO): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v1/cms/content/${dto.contentId}/add-share/`,
      data: { organisation_ids: dto.organisationIds }
    });
  }

  public static removeShareContent(dto: ShareContentDTO): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v1/cms/content/${dto.contentId}/remove-share/`,
      data: { organisation_ids: dto.organisationIds }
    });
  }

  public static fetchContentCategories(pageParam: URLSearchParams): Promise<AxiosResponse<IContentCategory>> {
    return dispatch({
      method: "GET",
      url: `/api/v2/cms/content-category/`,
      params: pageParam
    });
  }

  public static getPreSignedUrlForContent(id: number, fileName: string): Promise<AxiosResponse<PreSignedUlrResponse>> {
    return dispatch({
      method: "POST",
      url: `/api/v1/cms/content/${id}/pre-signed-url-content/`,
      data: { fileName }
    });
  }

  public static postPreviewImage(previewFile: any, headers: AxiosRequestHeaders): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v1/cms/preview-files/`,
      data: previewFile,
      headers: headers
    });
  }
  public static deletePreviewImage(id: number): Promise<AxiosResponse> {
    return dispatch({
      method: "DELETE",
      url: `/api/v1/cms/preview-files/${id}/`
    });
  }

  public static fetchLanguages(pageParam: URLSearchParams): Promise<AxiosResponse<IContentCategory>> {
    return dispatch({
      method: "GET",
      url: `/api/v2/cms/languages/`,
      params: pageParam
    });
  }
}
