import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { IContent } from "../../../../models/content";
import LoadingSpinner from "../../../ui/loadingSpinner/loadingSpinner";

import { HeroAreaElement } from "./heroAreaElement";

interface IContentListHeroAreaProps {
  displayContent: IContent[];
  showHeroArea: boolean;
}

export const ContentListHeroArea = ({ displayContent, showHeroArea }: IContentListHeroAreaProps) => {
  const [enableAutoRotation, setEnableAutoRotation] = useState(true);
  const [activeElementIndex, setActiveElementIndex] = useState(0);
  const refreshTimerRef = useRef<NodeJS.Timeout | null>(null);
  const activeElementIndexRef = useRef(0); // Ref to store the latest index

  const rotateActiveElement = () => {
    setActiveElementIndex(cur => {
      const nextIndex = cur + 1 < displayContent.length ? cur + 1 : 0;
      activeElementIndexRef.current = nextIndex; // Update ref value
      return nextIndex;
    });
  };

  const startInterval = () => {
    if (refreshTimerRef.current === null) {
      refreshTimerRef.current = setInterval(() => {
        rotateActiveElement();
      }, 4_000);
    }
  };

  const stopInterval = () => {
    if (refreshTimerRef.current !== null) {
      clearInterval(refreshTimerRef.current);
      refreshTimerRef.current = null;
    }
  };
  useEffect(() => {
    if (enableAutoRotation) {
      startInterval();
    } else {
      stopInterval();
    }

    return () => {
      stopInterval();
    };
  }, [enableAutoRotation]);

  if (!showHeroArea)
    return (
      <Box bgColor={"darkBlue.500"} textAlign={"center"}>
        <LoadingSpinner text={"Inhalte werden geladen"} />
      </Box>
    );
  if (displayContent.length === 0) return <></>;
  return (
    <>
      <Box
        bgColor={"darkBlue.500"}
        opacity={showHeroArea ? "100%" : "0"}
        transition="1.5s opacity"
        display={{ base: "none", sm: "none", md: "block" }}
      >
        <Flex
          opacity={showHeroArea ? "100%" : "0"}
          borderColor="darkBlue.500"
          borderTopColor={"darkBlue.500"}
          pb={2}
          minH={{ base: "180px", md: "220px", lg: "250px", "2xl": "360px" }}
          backgroundRepeat="no-repeat"
          onMouseEnter={() => setEnableAutoRotation(false)}
          onMouseLeave={() => setEnableAutoRotation(true)}
        >
          {displayContent.map((content, index) => {
            return (
              <HeroAreaElement
                key={index}
                setActiveElementIndex={setActiveElementIndex}
                index={index}
                enableAutoRotation={enableAutoRotation}
                activeElementIndex={activeElementIndex}
                displayContent={content}
              />
            );
          })}
        </Flex>
      </Box>
      <Box pt={4} opacity={showHeroArea ? "100%" : "0"} transition="1.5s opacity" height={"3rem"} bgColor="darkBlue.500">
        <Box
          opacity={showHeroArea ? "100%" : "0"}
          transition="1.5s opacity"
          borderTopRadius={"3xl"}
          height={"2rem"}
          bg="white"
        ></Box>
      </Box>
    </>
  );
};
