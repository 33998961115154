export interface ITutorialVideo {
  id: number;
  title: string;
  helpText: string;
  src: string;
}

export const TutorialVideos: ITutorialVideo[] = [
  {
    id: 1,
    title: "Mediathek",
    helpText: "In diesem Video zeigen wir Ihnen die Grundlegenden Eigenschaften und Funktionen unsere Mediathek.",
    src: "/videos/chapter_1.mp4/"
  },
  {
    id: 2,
    title: "Eigene Inhalte",
    helpText: "In diesem Video zeigen wir Ihnen wie Sie eigene Inhalte erstellen und benutzen können.",
    src: "/videos/chapter_2.mp4/"
  },
  {
    id: 3,
    title: "Produktverwaltung",
    helpText: "In diesem Video stellen wir Ihnen die Produktverwaltung und Ihre Funktionen vor.",
    src: "/videos/chapter_3.mp4/"
  },

  {
    id: 4,
    title: "Wiedergabelisten erstellen",
    helpText: "In diesem Video zeigen wir Ihnen wie Sie eigene Wiedergabelisten erstellen und benutzen können.",
    src: "/videos/chapter_4.mp4/"
  },
  {
    id: 5,
    title: "Synchronisieren",
    helpText: "In diesem Video erklären wir Ihnen alles was Sie über den Prozess des Synchronisieren wissen müssen.",
    src: "/videos/chapter_5.mp4/"
  },
  {
    id: 6,
    title: "Moderieren",
    helpText:
      "In diesem Video stellen wir Ihnen den Moderieren Bereich vor und erklären die genaue funktionsweise der Moderation.",
    src: "/videos/chapter_6.mp4/"
  },
  {
    id: 7,
    title: "Admin-Bereich",
    helpText: "In diesem Video erklären wir Ihnen alle wichtigen Funktionen des Admin-Bereiches.",
    src: "/videos/chapter_7.mp4/"
  },
  {
    id: 8,
    title: "Support Seite",
    helpText: "In diesem Video zeigen wir Ihnen alle Bereich der Support Seite und wo Sie welche Informationen finden.",
    src: "/videos/chapter_8.mp4/"
  },
  {
    id: 9,
    title: "VR-Brillen",
    helpText: "In diesem Video zeigen wir Ihnen alles wissenswerte über die von uns angebotenen VR-Brillen.",
    src: "https://vil-cdn.obs.eu-de.otc.t-systems.com/Hilfevideos/chapter_9.mp4"
  },
  {
    id: 10,
    title: "VIL-Lernkoffer",
    helpText:
      "In diesem Video zeigen wir Ihnen alles wissenswerte, über unsere VIL-Lernkoffer und dessen elektornischen Komponenten.",
    src: "https://vil-cdn.obs.eu-de.otc.t-systems.com/Hilfevideos/chapter_10.mp4"
  },
  {
    id: 11,
    title: "SSO: Bildungsmediathek NRW",
    helpText: "In diesem Video zeigen wir Ihnen wie Sie sich über die Bildungsmediathek NRW im VIL Portal anmelden können.",
    src: "https://vil-cdn.obs.eu-de.otc.t-systems.com/Hilfevideos/chapter_11.mp4"
  },
  {
    id: 12,
    title: "SSO: LOGINEO",
    helpText: "In diesem Video zeigen wir Ihnen wie Sie sich über LOGINEO im VIL Portal anmelden können.",
    src: "https://vil-cdn.obs.eu-de.otc.t-systems.com/Hilfevideos/chapter_12.mp4"
  }
];
