import { AxiosResponse, AxiosRequestHeaders, AxiosPromise } from "axios";

import { IContentCategory } from "../../models/contentCategory";
import { ILibraryContent, IMSBContent } from "../../models/content";
import { dispatch } from "./http";
import { content } from "../../../tailwind.config";

export type PreSignedUlrResponse = {
  url: string;
  uuid: string;
};

export class ApiMSBContentService {
  public static fetchAllContent(pageParam: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: "/api/v2/cms/content-msb/",
      params: pageParam
    });
  }

  public static fetchSingleContent(id: number): Promise<AxiosResponse<IMSBContent>> {
    return dispatch({
      method: "GET",
      url: `/api/v2/cms/content-msb/${id}/`
    });
  }
}
