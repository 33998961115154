import { Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { truncateString } from "../../../utils/helper";

interface ITruncateStringProps {
  targetString: string;
  length: number;
}

export default function TruncateString({ targetString, length }: ITruncateStringProps) {
  const [truncatedString, setTruncatedString] = useState("");

  useEffect(() => {
    setTruncatedString(truncateString(targetString, length));
  }, [setTruncatedString, targetString, length]);

  if (truncatedString === targetString) {
    return <>{truncatedString}</>;
  }

  return (
    <Tooltip borderRadius={"xl"} label={targetString}>
      {truncatedString}
    </Tooltip>
  );
}
