import { AxiosResponse } from "axios";
import { dispatch } from "./http";
import { IOrganisation } from "../../models/organisation";
import { IDevice } from "../../models/device";
import { IProduct } from "../../models/product";

export class ApiProductService {
  public static fetchAllProductsForOrganisation(): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v2/sdm/devices/`
    });
  }

  public static fetchSingleProductByUUID(serial_number: string): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v2/sdm/devices/${serial_number}/`
    });
  }

  public static activateConfigForProduct(
    serial_number: string,
    configId: number,
    mdmDeviceIds: number[]
  ): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v1/sdm/${serial_number}/device-conifg/${configId}/activate/`,
      data: { mdmDeviceIds }
    });
  }
}
