import React, { useState, useEffect, useMemo } from "react";

import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  IconButton,
  Image,
  Stack,
  Text,
  Link,
  useColorModeValue,
  useDisclosure,
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Icon
} from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon, ExternalLinkIcon, HamburgerIcon } from "@chakra-ui/icons";
import { UploadIcon, UserIcon } from "@heroicons/react/solid";
import { useAuth } from "../../provider/authentication";
import { User } from "../../models/user";
import { useRouter } from "next/router";
import { Size } from "../../constants";

interface NavItem {
  id: number;
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  onlyAuthenticated?: boolean;
  hideFromMSB?: boolean;
  onlyAdministrator?: boolean;
}

const socialOrganisationTypes = [4, 5];
const quickLinks = [
  {
    id: 1,
    name: "Marktplatz",
    href: "https://www.vil.digital/marktplatz",
    isExternal: true,
    icon: ExternalLinkIcon,
    hideFromMSB: true
  },
  {
    id: 2,
    name: "Inhalte hochladen",
    href: "/library/new",
    icon: UploadIcon
  }
];
const NAV_BASE: Array<NavItem> = [
  {
    id: 1,
    label: "Mediathek",
    href: "/library",
    onlyAuthenticated: false
  },
  {
    id: 2,
    label: "Produktverwaltung",
    onlyAuthenticated: true,
    children: [
      {
        id: 3,
        label: "Gruppierungen",
        href: "/groups",
        onlyAuthenticated: true,
        onlyAdministrator: true,
        hideFromMSB: true
      },
      {
        id: 4,
        label: "Produkte",
        href: "/products",
        onlyAuthenticated: true
      },
      {
        id: 5,
        label: "Wiedergabelisten",
        href: "/configurations",
        onlyAuthenticated: true
      }
    ]
  },
  {
    id: 6,
    label: "Moderieren",
    href: "/moderate",
    onlyAuthenticated: true
  }
];

function isPermittedToSeeNavItem(navItem: NavItem, user: User | null, isAuthenticated: boolean) {
  if (!navItem.onlyAuthenticated && !navItem.onlyAdministrator) return true;
  if (navItem.onlyAuthenticated && isAuthenticated && !navItem.hideFromMSB) return true;
  if (navItem.onlyAdministrator) {
    if (!user) return false;
    return isAuthenticated && user.isAdministratorOfCurrentOrganisation();
  }
  return false;
}

function isPermittedToSeeNavItemChild(navItem: NavItem, user: User | null) {
  const localIsSocial =
    Boolean(user?.isSocialUser) ||
    (user?.organisations &&
      user?.organisations?.length > 0 &&
      socialOrganisationTypes.includes(user?.organisations[0].organisationType));

  if (navItem.hideFromMSB && localIsSocial) {
    return false;
  }
  return true;
}

export const Menu: React.FC = () => {
  const router = useRouter();
  const [currentRoute, setCurrentRoute] = useState("");
  const { isOpen, onToggle } = useDisclosure();
  const { user, isAuthenticated, doLogout } = useAuth();

  const handleQuickLinkClick = (linkItem: any) => {
    if (currentRoute == linkItem.href) {
      return;
    } else {
      router.push(linkItem.href);
    }
  };

  const isAdmin = useMemo(() => {
    return user && user.isAdministratorOfCurrentOrganisation();
  }, [user]);

  const routeToLogin = () => {
    if (currentRoute != "/login") {
      router.push("/login");
    }
  };

  const hideMarketplaceFromUser = (user: User | null) => {
    if (!user) return true;
    if (
      Boolean(user?.isSocialUser) ||
      (user.organisations?.length > 0 && socialOrganisationTypes.includes(user.organisations[0].organisationType))
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setCurrentRoute(router.pathname);
  }, [router]);

  return (
    <>
      <Flex
        as="header"
        position="fixed"
        w="100%"
        zIndex={"10"}
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={Size.NAVBAR_HEIGHT}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex ml={{ base: -2 }} display={{ base: "flex", lg: "none" }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex gap={4} cursor={"pointer"}>
          <Link href={"/library"}>
            <Image pointerEvents={"none"} src={"/img/vil-logo.png"} height={"40px"} alt={"logo"} />
          </Link>

          <Flex display={{ base: "none", lg: "flex" }} ml={6}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Flex ml={"auto"} justify={"flex-end"} direction={"row"} gap={2}>
          {isAuthenticated && (
            <>
              {quickLinks.map((linkItem: any, index) => {
                return (
                  <React.Fragment key={linkItem.id}>
                    {linkItem.isExternal ? (
                      <>
                        {linkItem.hideFromMSB && hideMarketplaceFromUser(user) ? (
                          <></>
                        ) : (
                          <Button
                            as={"a"}
                            href={linkItem.href}
                            variant="outline"
                            target="_blank"
                            display={{ base: "none", md: "inherit" }}
                            size={{ base: "sm", md: "md", lg: "md" }}
                            title={linkItem.name}
                            isDisabled={currentRoute == linkItem.href}
                            leftIcon={<linkItem.icon className="w-5 h-5" />}
                          >
                            Marktplatz
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        as={"a"}
                        href={linkItem.href}
                        key={linkItem.id}
                        color={"darkBlue.500"}
                        size={{ base: "sm", md: "md", lg: "md" }}
                        title={linkItem.name}
                        variant={"ghost"}
                        pointerEvents={currentRoute == linkItem.href ? "none" : "auto"}
                        isDisabled={currentRoute == linkItem.href}
                        onClick={() => {
                          handleQuickLinkClick(linkItem);
                        }}
                      >
                        <linkItem.icon className="w-5 h-5" /> {linkItem.name == "Mein Profil" && <ChevronDownIcon />}
                      </Button>
                    )}
                  </React.Fragment>
                );
              })}

              <ChakraMenu>
                <MenuButton
                  size={{ base: "sm", md: "md", lg: "md" }}
                  colorScheme={"darkBlue"}
                  as={Button}
                  borderRadius="3xl"
                  rightIcon={<ChevronDownIcon />}
                >
                  <UserIcon className="w-5 h-5 text-white" />
                </MenuButton>
                <MenuList borderRadius="3xl" overflow={"hidden"}>
                  <MenuItem as={"a"} href={"/profile"}>
                    Mein Profil
                  </MenuItem>
                  <MenuItem as={"a"} href={"/support"}>
                    Support - Hilfeseite
                  </MenuItem>
                  <Divider />
                  {isAdmin && (
                    <>
                      <MenuItem as={"a"} href={"/administration/"}>
                        Adminbereich
                      </MenuItem>

                      <Divider />
                    </>
                  )}

                  <MenuItem onClick={() => doLogout()}>Ausloggen</MenuItem>
                </MenuList>
              </ChakraMenu>
            </>
          )}
          {!isAuthenticated && (
            <Button
              isDisabled={currentRoute === "/login"}
              onClick={routeToLogin}
              fontSize={"sm"}
              borderRadius="3xl"
              fontWeight={400}
              variant={"outline"}
            >
              Einloggen
            </Button>
          )}
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </>
  );
};

const DesktopSubNav = ({ label, href, subLabel, onlyAuthenticated, onlyAdministrator }: NavItem) => {
  const to = href === undefined ? "" : href;
  const { isAuthenticated } = useAuth();
  const { user } = useAuth();

  const isAdmin = useMemo(() => {
    return user && user.isAdministratorOfCurrentOrganisation();
  }, [user]);
  if (onlyAdministrator && user && !isAdmin) {
    return <></>;
  }
  if (!onlyAuthenticated || (onlyAuthenticated && isAuthenticated)) {
    return (
      <Link role={"group"} href={to}>
        <Stack direction={"row"} align={"center"}>
          <Box>
            <Text transition={"all .3s ease"} fontWeight={600}>
              {label}
            </Text>
            <Text fontSize={"sm"}>{subLabel}</Text>
          </Box>
        </Stack>
      </Link>
    );
  }
  return null;
};

const DesktopNav: React.FC = () => {
  const router = useRouter();
  const [currentRoute, setCurrentRoute] = useState("");
  const { isAuthenticated, user } = useAuth();
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  const navItemIsActive = (navItem: NavItem) => {
    if (currentRoute && navItem.href) {
      if (currentRoute == navItem.href) return true;
    }
    if (navItem.label == "Moderieren" && currentRoute.includes("moderate")) {
      return true;
    }
    if (
      navItem.label == "Mediathek" &&
      (currentRoute.includes("msb_library") || currentRoute.includes("library") || currentRoute === "/")
    ) {
      return true;
    }
    if (
      navItem.label == "Produktverwaltung" &&
      (currentRoute.includes("products") || currentRoute.includes("configurations") || currentRoute.includes("groups"))
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    setCurrentRoute(router.pathname);
  }, [router]);

  return (
    <Stack direction={"row"} spacing={2} my={"auto"} cursor={"pointer"} fontFamily="Bitter">
      {NAV_BASE?.map((navItem: NavItem, index) => {
        if (isPermittedToSeeNavItem(navItem, user, isAuthenticated)) {
          if (navItem.children && navItem.children.length > 0) {
            return (
              <Box _hover={{ textDecor: "none" }} key={navItem.id} my={"auto"} role={"group"}>
                <Popover trigger={"hover"} placement={"bottom-start"}>
                  <PopoverTrigger>
                    <Box
                      py={1}
                      px={2}
                      fontSize={"lg"}
                      fontWeight={600}
                      color={navItemIsActive(navItem) ? "darkBlue.500" : linkColor}
                      borderBottom={navItemIsActive(navItem) ? "2px solid" : "none"}
                      borderColor={"darkBlue.500"}
                      _hover={{
                        textDecoration: "none",
                        color: navItemIsActive(navItem) ? "darkBlue.500" : linkHoverColor,
                        borderColor: "darkBlue.500"
                      }}
                    >
                      <>
                        {navItem.label}

                        <ChevronDownIcon />
                      </>
                    </Box>
                  </PopoverTrigger>

                  {navItem.children && (
                    <PopoverContent
                      mt={"2"}
                      border={0}
                      boxShadow={"xl"}
                      bg={popoverContentBgColor}
                      p={4}
                      rounded={"xl"}
                      minW={"sm"}
                    >
                      <Stack>
                        {navItem.children.map((child, index) => {
                          if (isPermittedToSeeNavItemChild(child, user)) {
                            return <DesktopSubNav key={index} {...child} />;
                          }
                          return <React.Fragment key={index}></React.Fragment>;
                        })}
                      </Stack>
                    </PopoverContent>
                  )}
                </Popover>
              </Box>
            );
          } else {
            return (
              <Link
                href={navItem.href}
                key={navItem.id}
                my={"auto"}
                role={"group"}
                onClick={() => {
                  router.push(navItem.href ?? "#");
                }}
                py={1}
                px={2}
                fontSize={"lg"}
                fontWeight={600}
                color={navItemIsActive(navItem) ? "darkBlue.500" : linkColor}
                borderBottom={navItemIsActive(navItem) ? "2px solid" : "none"}
                borderColor={"darkBlue.500"}
                _hover={{
                  textDecoration: "none",
                  color: navItemIsActive(navItem) ? "darkBlue.500" : linkHoverColor,
                  borderColor: "darkBlue.500"
                }}
              >
                <>
                  {navItem.label}
                  {!!navItem.children && (
                    <ChevronDownIcon transition={"all 0.25s ease-out"} _groupHover={{ transform: "rotate(180deg)" }} />
                  )}
                </>
              </Link>
            );
          }
        }
        return null;
      })}
    </Stack>
  );
};

const MobileNav = () => {
  const { isAuthenticated, user } = useAuth();

  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      position="fixed"
      zIndex={"10"}
      w={"100%"}
      top={Size.NAVBAR_HEIGHT}
      display={{ lg: "none" }}
    >
      {NAV_BASE.map((navItem, index) => {
        if (isPermittedToSeeNavItem(navItem, user, isAuthenticated)) {
          return <MobileNavItem key={index} {...navItem} />;
        } else {
          return null;
        }
      })}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { user } = useAuth();
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Link href={href ?? "#"}>
        <Text
          py={2}
          textAlign={"left"}
          align={"center"}
          _hover={{
            textDecoration: "none"
          }}
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
      </Link>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child, index) => {
              if (isPermittedToSeeNavItemChild(child, user)) {
                return (
                  <Link key={index} href={child.href ?? "#"}>
                    {child.label}
                  </Link>
                );
              }
              return <React.Fragment key={index}></React.Fragment>;
            })}
        </Stack>
      </Collapse>
    </Stack>
  );
};
