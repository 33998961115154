import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Container,
  Flex,
  HStack,
  Input,
  Popover,
  Tag,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Text,
  Link,
  VStack,
  Image,
  Heading,
  useBreakpointValue,
  Divider,
  MenuList,
  MenuItem,
  Menu,
  MenuButton,
  IconButton,
  useDisclosure
} from "@chakra-ui/react";
import { ContentSortingString, DEVICE_TYPES, IContent, ILibraryContent, IMSBContent } from "../../../../models/content";

import { ContentCard } from "./contentCard";
import { useAuth } from "../../../../provider/authentication";

import { NoContentFound } from "../../../ui/no-content/noContentFound";
import { IContentCategory } from "../../../../models/contentCategory";
import { useRouter } from "next/router";
import { ContentListHeroArea } from "./contentListHeroArea";

import ScrollToTopButton from "../../../utilities/scrollToTopButton";
import LoadingSpinner from "../../../ui/loadingSpinner/loadingSpinner";
import { shuffleArray, VIL_OWNER_ID } from "../../../../utils/helper";
import { ContentListTagSelection } from "./marketplace/contentListTagSelection";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CubeIcon } from "@heroicons/react/outline";
import {
  BackspaceIcon,
  EyeIcon,
  EyeOffIcon,
  GlobeAltIcon,
  PlusIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  UploadIcon,
  VideoCameraIcon
} from "@heroicons/react/solid";
import { MdPublic, MdPublicOff } from "react-icons/md";
import { FaShare, FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
import Pagination from "../../../ui/pagination/pagination";
import { ResponsiveButtonIcon } from "../../../ui/responsiveButtonIcon/responsiveButtonIcon";

export interface ICategoryTag {
  name: string;
  counter: number;
}

export type TopContent = {
  category: IContentCategory;
  content: IContent[];
};
interface SortingOption {
  name: string;
  value: ContentSortingString;
  icon: any;
}

const sortingOptions: SortingOption[] = [
  {
    name: "Neueste zuerst",
    value: ContentSortingString.UPDATED_AT_ASC,
    icon: <SortAscendingIcon className="w-5 h-5" />
  },
  {
    name: "Älteste zuerst",
    value: ContentSortingString.UPDATED_AT_DESC,
    icon: <SortDescendingIcon className="w-5 h-5" />
  },
  {
    name: "Name: Aufsteigend",
    value: ContentSortingString.ALPHABET_ASC,
    icon: <FaSortAlphaUp className="w-5 h-5" />
  },
  {
    name: "Name: Absteigend",
    value: ContentSortingString.ALPHABET_DESC,
    icon: <FaSortAlphaDown className="w-5 h-5" />
  }
];

type ContentListProps = {
  content: IContent[];
  msbContent?: IContent[];
  renderFilter: boolean;
  hasMSBContentAcess?: boolean;
  isLoadingContent?: boolean;
  finishedLoadingAllContent?: boolean;
  showHeroArea?: boolean;
  uniqueDeviceTypeIDs: string[];
};

export const ContentList: React.FC<ContentListProps> = ({
  content,
  msbContent = [],
  renderFilter,
  uniqueDeviceTypeIDs,
  hasMSBContentAcess,
  isLoadingContent = false,
  finishedLoadingAllContent = false,
  showHeroArea = false
}) => {
  const CONTENT_PER_PAGE = 24;
  const { user, isAuthenticated } = useAuth();
  const displayContentLength = useBreakpointValue({ base: 2, sm: 3, md: 3, lg: 4, "2xl": 5 });
  const [categoryFilterState, setCategoryFilterState] = useState<Array<IContentCategory>>([]);
  const [typeFilterState, setTypeFilterState] = useState<Array<string>>([]);

  const [ownershipFilterState, setOwnershipFilterState] = useState<Array<string>>([]);
  const [showFilter, setShowFilter] = useState(false);
  const [sortingString, setSortingString] = useState(ContentSortingString.UPDATED_AT_ASC);

  const [deviceTypeFilterState, setDeviceTypeFilterState] = useState<Array<string>>([]);
  const [contentSearchString, setContentSearchString] = useState("");
  const [tagSearchString, setTagSearchString] = useState("");

  const [localContentList, setLocalContentList] = useState<Array<IContent>>([]);

  const [showTagArea, setShowTagArea] = useState(false);

  //pagination
  const [libraryCurrentPage, setLibraryCurrentPage] = useState(1);
  const [libraryTotalPages, setLibraryTotalPages] = useState(0);
  const libraryStartIndex = (libraryCurrentPage - 1) * CONTENT_PER_PAGE;
  const [libraryLimitPerPage, setLibraryLimitPerPage] = useState(CONTENT_PER_PAGE);

  const sortingMenuDisclosure = useDisclosure();
  const { isOpen: sortingMenuIsOpen, onOpen: sortingMenuOnOpen, onClose: sortingMenuOnClose } = sortingMenuDisclosure;

  const router = useRouter();
  const atleastOneFilterIsSelected =
    categoryFilterState.length > 0 ||
    typeFilterState.length > 0 ||
    deviceTypeFilterState.length > 0 ||
    ownershipFilterState.length > 0 ||
    contentSearchString.length > 0;

  const resetFilters = () => {
    if (categoryFilterState.length > 0) {
      setCategoryFilterState([]);
    }
    if (typeFilterState.length > 0) {
      setTypeFilterState([]);
    }
    if (ownershipFilterState.length > 0) {
      setOwnershipFilterState([]);
    }
    if (deviceTypeFilterState.length > 0) {
      setDeviceTypeFilterState([]);
    }
    if (contentSearchString.length > 0) {
      setContentSearchString("");
    }

    clearCache();
  };

  const toggleCachedCategories = (cats: IContentCategory[]) => {
    setCategoryFilterState(cats);
    saveFiltersToCache("vilPortal--categories", cats);
  };

  const handleSortContent = (eventSortingString: ContentSortingString) => {
    sortingMenuOnClose();
    if (sortingString === eventSortingString) {
      setSortingString(ContentSortingString.UPDATED_AT_ASC);
    } else {
      setSortingString(eventSortingString);
    }
  };

  const toggleCategory = (category: any) => {
    let updatedCategories = categoryFilterState.slice();
    const objectIsIncluded = updatedCategories.some(obj => contentCategoryIsEqual(obj.name, category.name));
    if (objectIsIncluded) {
      updatedCategories = updatedCategories.filter(obj => {
        return !contentCategoryIsEqual(category.name, obj.name);
      });
      setCategoryFilterState(updatedCategories);
    } else {
      updatedCategories.push(category);
      setCategoryFilterState(updatedCategories);
    }
  };

  function getTopCategoriesWithContent(contentArray: IContent[]): TopContent[] {
    // Count the occurrences of each category
    const categoryCounts: Record<number, { category: IContentCategory; count: number }> = {};

    for (const content of contentArray) {
      for (const category of content.contentCategories) {
        const catId = category.id;
        if (!categoryCounts[catId]) {
          categoryCounts[catId] = { category, count: 0 };
        }
        categoryCounts[catId].count++;
      }
    }

    // Sort categories by count (descending) and pick the top 4
    const topCategories = Object.values(categoryCounts)
      .sort((a, b) => b.count - a.count)
      .slice(0, 2)
      .map(entry => entry.category);

    // Build an array of results
    const result: TopContent[] = topCategories.map(category => ({
      category,
      content: contentArray.filter(content => content.contentCategories.some((cat: IContentCategory) => cat.id === category.id))
    }));

    return result;
  }

  const contentCategoryIsEqual = (a: string, b: string): boolean => {
    return a === b;
  };

  const selectedSortingFilter = (): SortingOption => {
    return sortingOptions.filter(option => option.value === sortingString)[0];
  };

  const showContentBasedOnDeviceType = (content: ILibraryContent | IMSBContent) => {
    if ("arixId" in content) return true;
    if (content.contentType === "video") return true;

    if (content?.compatibleDevices) {
      const compatibleDeviceIds = content.compatibleDevices.map(device => device.id);
      if (compatibleDeviceIds.length === 0) return true;
      return compatibleDeviceIds.some(num => deviceTypeFilterState.includes(String(num)));
    }
    return false;
  };

  const showContentBasedOnOwnership = (content: ILibraryContent | IMSBContent) => {
    const showPrivate = ownershipFilterState.includes("private");
    const showPublic = ownershipFilterState.includes("public");
    const showShared = ownershipFilterState.includes("shared");
    const showMSB = ownershipFilterState.includes("MSB");

    if (showPublic && showPrivate && showShared) {
      return true;
    }
    if (showPublic && showPrivate) {
      return true;
    }

    if (showPublic) {
      return content.owner && content.owner.id === VIL_OWNER_ID;
    }

    if (showPrivate) {
      return content.owner && user?.firstOrganisation!.id === content.owner.id && !("arixId" in content);
    }
    if (showShared) {
      return content.owner && user?.firstOrganisation!.id != content.owner.id && content.owner.id != VIL_OWNER_ID;
    }

    if (showMSB) {
      return "arixId" in content;
    }
    return false;
  };

  const onPageChange = (page: number) => {
    setLibraryCurrentPage(page);
  };

  const handleTypeFilterChange = (filterState: Array<string>) => {
    setTypeFilterState(filterState);
    saveFiltersToCache("vilPortal--contentType", filterState);
  };
  const returnFormattedCategories = (contentList: any[]) => {
    const createUniqueCategories = (contentList: any) => {
      let uniqueCategories: ICategoryTag[] = [];
      contentList.forEach((content: IContent) => {
        if ("arixId" in content) {
          const msbContent: any = content;

          msbContent.arixTags.forEach((category: string) => {
            const objectIsIncluded = uniqueCategories.some(obj => contentCategoryIsEqual(obj.name, category));
            if (!objectIsIncluded) {
              uniqueCategories.push({ name: category.trim(), counter: 0 });
            }
          });
        } else if (content.contentCategories) {
          content.contentCategories.forEach((category: IContentCategory) => {
            const objectIsIncluded = uniqueCategories.some(obj => contentCategoryIsEqual(obj.name, category.name));
            if (!objectIsIncluded) {
              uniqueCategories.push({ name: category.name, counter: 0 });
            }
          });
        }
      });
      return uniqueCategories;
    };

    const returnSortedCategoriesWithCounter = (categories: ICategoryTag[]) => {
      let uniqueObjects: ICategoryTag[] = [];
      categories.forEach(el => {
        let counter = 0;
        contentList.forEach((content: any) => {
          let targetArray = [];
          if ("arixId" in content) {
            targetArray = content.arixTags;
          } else {
            targetArray = content.contentCategories;
          }

          const objectIsIncluded = targetArray.some((obj: any) => {
            if (obj.name) {
              return contentCategoryIsEqual(obj.name, el.name);
            } else {
              return contentCategoryIsEqual(obj.trim(), el.name);
            }
          });
          if (objectIsIncluded) {
            counter++;
          }
        });
        el.counter = counter;
        uniqueObjects.push(el);
      });
      uniqueObjects.sort((a, b) => {
        if (a.counter < b.counter) {
          return 1;
        }
        if (a.counter > b.counter) {
          return -1;
        }
        return 0;
      });

      return uniqueObjects;
    };

    const uniqueCategories = createUniqueCategories(contentList);

    const categoriesWithCounter = returnSortedCategoriesWithCounter(uniqueCategories);
    const uniqueItems = categoriesWithCounter.filter((item, index, self) => index === self.findIndex(t => t.name === item.name));
    return uniqueItems;
  };
  const handleOwnershipFilterChange = (filterState: Array<string>) => {
    setOwnershipFilterState(filterState);
    saveFiltersToCache("vilPortal--ownership", filterState);
  };
  const handleDeviceTypeFilterChange = (filterState: Array<string>) => {
    setDeviceTypeFilterState(filterState);
  };

  const sortList = (contentList: IContent[], eventSortingString: ContentSortingString) => {
    switch (eventSortingString) {
      case ContentSortingString.ALPHABET_ASC:
        return contentList.sort((a, b) => {
          return a.title!.localeCompare(b.title!);
        });

      case ContentSortingString.ALPHABET_DESC:
        return contentList.sort((a, b) => {
          return b.title!.localeCompare(a.title!);
        });

      case ContentSortingString.UPDATED_AT_ASC:
        return contentList.sort((a, b) => {
          if (new Date(a.createdAt!) < new Date(b.createdAt!)) {
            return 1;
          }
          if (new Date(a.createdAt!) > new Date(b.createdAt!)) {
            return -1;
          }
          return 0;
        });

      case ContentSortingString.UPDATED_AT_DESC:
        return contentList.sort((a, b) => {
          if (new Date(a.createdAt!) > new Date(b.createdAt!)) {
            return 1;
          }
          if (new Date(a.createdAt!) < new Date(b.createdAt!)) {
            return -1;
          }
          return 0;
        });
    }
  };
  // Saving array to cache
  const saveFiltersToCache = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data));
  };

  // Retrieving array from cache
  const getFiltersFromCache = (key: string) => {
    const cacheData = localStorage.getItem(key);
    return cacheData ? JSON.parse(cacheData) : null;
  };

  const clearCache = () => {
    Object.keys(localStorage).forEach(key => {
      // Check if the key matches your criteria
      if (key.startsWith("vilPortal")) {
        localStorage.removeItem(key);
      }
    });
  };

  const filteredContent = useMemo(() => {
    let content = localContentList.slice();
    if (
      categoryFilterState.length === 0 &&
      typeFilterState.length === 0 &&
      deviceTypeFilterState.length === 0 &&
      ownershipFilterState.length === 0 &&
      contentSearchString.length === 0
    ) {
      return sortList(localContentList, sortingString);
    }

    content = content.filter((singleContent: any) => {
      let hasValidCategories = categoryFilterState.length === 0;
      if (!hasValidCategories) {
        hasValidCategories = categoryFilterState.every((entry: IContentCategory) => {
          if (singleContent.arixTags) {
            return singleContent.arixTags.some((obj: string) => contentCategoryIsEqual(obj.trim(), entry.name));
          }

          return singleContent.contentCategories.some((obj: IContentCategory) => contentCategoryIsEqual(obj.name, entry.name));
        });
      }
      const lowerCaseSearchString = contentSearchString.toLowerCase();

      return (
        hasValidCategories &&
        (deviceTypeFilterState.length === 0 || showContentBasedOnDeviceType(singleContent)) &&
        (ownershipFilterState.length === 0 || showContentBasedOnOwnership(singleContent)) &&
        (typeFilterState.length === 0 || typeFilterState.includes(singleContent.contentType!)) &&
        (contentSearchString === "" ||
          [
            singleContent.title,
            singleContent.licensor,
            ...(singleContent.contentCategories || []).map((entry: IContentCategory) => entry.name),
            ...(singleContent.arixTags || []),
            ...(singleContent.arixSubjectAreas || [])
          ].some(field => field?.toLowerCase().includes(lowerCaseSearchString)))
      );
    });
    return sortList(content, sortingString);
  }, [
    localContentList,
    categoryFilterState,
    libraryStartIndex,
    sortingString,
    libraryLimitPerPage,
    deviceTypeFilterState,
    typeFilterState,
    ownershipFilterState,
    contentSearchString
  ]);

  const paginatedContent = useMemo(() => {
    if (filteredContent.length != 0) {
      setLibraryTotalPages(Math.ceil(filteredContent.length / libraryLimitPerPage));
      if (filteredContent.length < libraryLimitPerPage) {
        return filteredContent;
      } else {
        const targetPage = filteredContent.slice(libraryStartIndex, libraryStartIndex + libraryLimitPerPage);
        if (targetPage.length === 0) {
          setLibraryCurrentPage(1);
        }
        return targetPage;
      }
    }

    return [];
  }, [filteredContent, libraryStartIndex, libraryLimitPerPage]);

  const dynamicFilteredCategories = useMemo(() => {
    return returnFormattedCategories(filteredContent);
  }, [filteredContent]);

  const filteredTags = useMemo(() => {
    if (tagSearchString.length === 0 && typeFilterState.length === 0) {
      return dynamicFilteredCategories;
    }
    const categories = dynamicFilteredCategories.filter((singleTag: any) => {
      return (
        (tagSearchString === "" && categoryFilterState.length === 0) ||
        singleTag.name.toLowerCase().includes(tagSearchString.toLowerCase()) ||
        categoryFilterState.some(obj => contentCategoryIsEqual(obj.name, singleTag.name))
      );
    });
    return categories;
  }, [tagSearchString, typeFilterState, dynamicFilteredCategories, categoryFilterState]);

  // const newContent = useMemo(() => {
  //   if (finishedLoadingAllContent) {
  //     return localContentList.filter(content => {
  //       return content.isNew;
  //     });
  //   }
  //   return [];
  // }, [finishedLoadingAllContent, localContentList]);

  // const topContentCategories = useMemo(() => {
  //   if (finishedLoadingAllContent) {
  //     return getTopCategoriesWithContent(localContentList);
  //   }
  //   return undefined;
  // }, [finishedLoadingAllContent, localContentList]);

  const handleSetLimit = (newLimit: number) => {
    setLibraryLimitPerPage(newLimit);
    setLibraryCurrentPage(1);
  };

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      resetFilters();
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (msbContent && msbContent.length > 0) {
      const mergedArray = content.concat(msbContent);
      mergedArray.sort((a, b) => a.diffInDays! - b.diffInDays!);
      setLocalContentList(mergedArray);
    } else {
      setLocalContentList(content);
    }
  }, [content, msbContent]);

  useEffect(() => {
    const cachedTags = getFiltersFromCache("vilPortal--categories");
    if (cachedTags) {
      toggleCachedCategories(cachedTags);
    }
    const cachedContentType = getFiltersFromCache("vilPortal--contentType");
    if (cachedContentType) {
      setTypeFilterState(cachedContentType);
    }

    const showOwnContent = getFiltersFromCache("vilPortal--showOwnContent");
    if (showOwnContent) {
      let userOrgID = String(user?.firstOrganisation?.id);
      setOwnershipFilterState([userOrgID]);
    }
  }, [isAuthenticated, user?.firstOrganisation?.id, uniqueDeviceTypeIDs]);

  useEffect(() => {
    if (isAuthenticated && uniqueDeviceTypeIDs && uniqueDeviceTypeIDs.length > 0) {
      setDeviceTypeFilterState(uniqueDeviceTypeIDs);
    }
  }, [isAuthenticated, uniqueDeviceTypeIDs]);

  const displayContent = useMemo(() => {
    if (content && finishedLoadingAllContent) {
      const publicContent = content.filter(content => content.owner.id === 71);

      return shuffleArray(publicContent).slice(0, displayContentLength);
    }
    return [];
  }, [content, displayContentLength, finishedLoadingAllContent]);

  return (
    <>
      <Heading
        size={"md"}
        bgColor={"darkBlue.500"}
        color={"white"}
        fontSize={["32px", "32px", "32px", "40px", "48px"]}
        fontFamily="Bitter"
        pt={8}
        pb={{ base: 4, md: 8 }}
        fontWeight={600}
        display={"flex"}
        gap={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box
          w={[16, 16, 16, 20]}
          h={[12, 14, 14, 16]}
          bgImage={"/img/VIL_Logo_negativ_Bildmarke.svg"}
          backgroundPosition={"center"}
          backgroundRepeat="no-repeat"
          backgroundSize={"contain"}
        ></Box>
        Mediathek
      </Heading>
      {isLoadingContent && (
        <Box mt={24} w={"full"} h={"full"} textAlign={"center"}>
          <LoadingSpinner text={"Inhalte werden geladen"} />
        </Box>
      )}

      <ContentListHeroArea
        showHeroArea={showHeroArea && displayContent.length > 0 && !isLoadingContent}
        displayContent={displayContent}
      />
      <ScrollToTopButton />
      <Box maxW={"90%"} mx={"auto"} p={2}>
        <Box mt={4} minH={"50vh"} width={"full"} rounded={"lg"} pos="relative">
          {isAuthenticated && renderFilter && (
            <Link
              display={{ base: "none", sm: "none", md: "block" }}
              href={"/library/new"}
              float="right"
              pos={"absolute"}
              right={0}
              top={0}
            >
              <Button borderRadius={"3xl"} size={"sm"} leftIcon={<ResponsiveButtonIcon icon={UploadIcon} intialValue={4} />}>
                Inhalt erstellen
              </Button>
            </Link>
          )}
          <Flex gap={2} alignItems="center" w={"full"} flexWrap="wrap">
            <Input
              value={contentSearchString}
              size={"sm"}
              borderRadius="xl"
              w={["10rem", "20rem"]}
              placeholder={"Suchbegriff eingeben..."}
              type={"text"}
              onInput={(e: any) => {
                setContentSearchString(e.target.value);
              }}
            />
            <Button size={"sm"} onClick={() => setShowFilter(!showFilter)}>
              Filter {showFilter ? "ausblenden" : "einblenden"}{" "}
            </Button>
            {isAuthenticated && (
              <Flex justify={"center"} align="center" gap={1}>
                {atleastOneFilterIsSelected && (
                  <Text ml={2} fontSize={["sm", "sm", "md"]} color={"darkBlue.500"} fontWeight={600}>
                    {filteredContent.length} /
                  </Text>
                )}
                <Text ml={atleastOneFilterIsSelected ? 0 : 2} fontSize={["sm", "sm", "md"]} color={"gray.500"} fontWeight={600}>
                  {content.length + msbContent?.length} Inhalte
                </Text>{" "}
              </Flex>
            )}
          </Flex>

          {renderFilter && showFilter && (
            <>
              <Divider my={2} />
              <Flex align={"center"} flexWrap="wrap" mt={4}>
                <HStack w={"full"} ml={2} spacing={"2"}>
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        colorScheme={"darkBlue"}
                        borderRadius={"3xl"}
                        bgColor={typeFilterState.length <= 0 ? "white" : "none"}
                        size={["xs", "xs", "sm"]}
                        variant={typeFilterState.length > 0 ? "solid" : "outline"}
                      >
                        Inhaltstyp <ChevronDownIcon w={"4"} h={"4"} />
                      </Button>
                    </PopoverTrigger>

                    <PopoverContent borderRadius={"3xl"}>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Inhaltstyp auswählen</PopoverHeader>
                      <PopoverBody>
                        <Container p={"4"}>
                          <CheckboxGroup
                            defaultValue={typeFilterState}
                            value={typeFilterState}
                            onChange={handleTypeFilterChange}
                          >
                            <VStack spacing={[1, 5]} direction={["column", "row"]} justifyContent={"start"} alignItems={"start"}>
                              <Checkbox value={"video"}>
                                <HStack>
                                  <Box w={4} h={4}>
                                    <VideoCameraIcon />
                                  </Box>
                                  <div>360° Video</div>
                                </HStack>
                              </Checkbox>
                              <Checkbox value={"app"}>
                                <HStack>
                                  <Box w={4} h={4}>
                                    <CubeIcon />
                                  </Box>
                                  <div>Anwendung</div>
                                </HStack>
                              </Checkbox>
                              <Checkbox value={"webApp"}>
                                <HStack>
                                  <Box w={4} h={4}>
                                    <GlobeAltIcon />
                                  </Box>
                                  <div>Web App</div>
                                </HStack>
                              </Checkbox>
                            </VStack>
                          </CheckboxGroup>
                        </Container>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        colorScheme={"darkBlue"}
                        borderRadius={"3xl"}
                        bgColor={deviceTypeFilterState.length <= 0 ? "white" : "none"}
                        size={["xs", "xs", "sm"]}
                        variant={deviceTypeFilterState.length > 0 ? "solid" : "outline"}
                      >
                        VR-Brille <ChevronDownIcon w={"4"} h={"4"} />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent borderRadius={"3xl"}>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>VR-Brille auswählen</PopoverHeader>
                      <PopoverBody w={"full"}>
                        <Container p={"4"} w={"full"}>
                          <CheckboxGroup
                            defaultValue={deviceTypeFilterState}
                            value={deviceTypeFilterState}
                            onChange={handleDeviceTypeFilterChange}
                          >
                            <VStack spacing={[1, 5]} direction={["column", "row"]} justifyContent={"start"} alignItems={"start"}>
                              {DEVICE_TYPES.map((deviceType, index) => {
                                return (
                                  <Checkbox value={String(deviceType.id)} key={index}>
                                    {deviceType.producer} {deviceType.name}
                                  </Checkbox>
                                );
                              })}
                            </VStack>
                          </CheckboxGroup>
                        </Container>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  {isAuthenticated && (
                    <>
                      <Popover>
                        <PopoverTrigger>
                          <Button
                            colorScheme={"darkBlue"}
                            borderRadius={"3xl"}
                            bgColor={ownershipFilterState.length <= 0 ? "white" : "none"}
                            size={["xs", "xs", "sm"]}
                            variant={ownershipFilterState.length > 0 ? "solid" : "outline"}
                          >
                            Quelle <ChevronDownIcon w={"4"} h={"4"} />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent borderRadius={"3xl"}>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>Quelle auswählen</PopoverHeader>
                          <PopoverBody w={"full"}>
                            <Container p={"4"} w={"full"}>
                              <CheckboxGroup
                                defaultValue={ownershipFilterState}
                                value={ownershipFilterState}
                                onChange={handleOwnershipFilterChange}
                              >
                                <VStack
                                  spacing={[1, 5]}
                                  direction={["column", "row"]}
                                  justifyContent={"start"}
                                  alignItems={"start"}
                                >
                                  <Checkbox value={"public"}>
                                    <HStack>
                                      <Box w={4} h={4}>
                                        <MdPublic />
                                      </Box>
                                      <Text> Öffentliche Inhalte</Text>
                                    </HStack>
                                  </Checkbox>
                                  <Checkbox value={"private"}>
                                    <HStack>
                                      <Box w={4} h={4}>
                                        <MdPublicOff />
                                      </Box>
                                      <Text> Eigene Inhalte</Text>
                                    </HStack>
                                  </Checkbox>
                                  {!hasMSBContentAcess && (
                                    <Checkbox value={"shared"}>
                                      <HStack>
                                        <Box w={4} h={4}>
                                          <FaShare />
                                        </Box>
                                        <Text> Geteilte Inhalte</Text>
                                      </HStack>
                                    </Checkbox>
                                  )}
                                  {hasMSBContentAcess && (
                                    <Checkbox value={"MSB"}>
                                      <HStack>
                                        <Box w={32} mr={"-.5rem"}>
                                          <Image src="/img/bildungsmediathek-nwr-logo.svg"></Image>
                                        </Box>
                                      </HStack>
                                    </Checkbox>
                                  )}
                                </VStack>
                              </CheckboxGroup>
                            </Container>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </>
                  )}
                  <Button
                    leftIcon={
                      showTagArea ? (
                        <ResponsiveButtonIcon intialValue={4} icon={EyeOffIcon} />
                      ) : (
                        <ResponsiveButtonIcon intialValue={4} icon={EyeIcon} />
                      )
                    }
                    size={["xs", "xs", "sm"]}
                    variant={showTagArea ? "solid" : "outline"}
                    onClick={() => setShowTagArea(!showTagArea)}
                  >
                    Tags
                  </Button>
                  {atleastOneFilterIsSelected && (
                    <HStack verticalAlign={"center"}>
                      <Button
                        background={"red.500"}
                        borderRadius={"3xl"}
                        size={["xs", "xs", "sm"]}
                        onClick={resetFilters}
                        _hover={{
                          bg: "red.600"
                        }}
                      >
                        <ResponsiveButtonIcon intialValue={4} icon={BackspaceIcon} />
                      </Button>
                    </HStack>
                  )}{" "}
                  <Flex gap={2} ml="auto">
                    <Text display={["none", "none", "initial", "initial"]} alignSelf={"center"} color={"gray.600"}>
                      {selectedSortingFilter().name}
                    </Text>
                    <Menu isOpen={sortingMenuIsOpen}>
                      <MenuButton
                        transition="all 1s"
                        onClick={sortingMenuOnOpen}
                        as={IconButton}
                        borderRadius="3xl"
                        aria-label="Options"
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke={sortingMenuIsOpen ? "white" : "currentColor"}
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                            />
                          </svg>
                        }
                        variant="outline"
                      />

                      <MenuList zIndex={3}>
                        {sortingOptions.map(item => {
                          return (
                            <MenuItem
                              onClick={() => handleSortContent(item.value)}
                              icon={item.icon}
                              bgColor={item.value === sortingString ? "blue.100" : "unset"}
                              key={item.name}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </Flex>
                </HStack>
              </Flex>
              {showTagArea && (
                <ContentListTagSelection
                  categoryFilterState={categoryFilterState}
                  contentCategoryIsEqual={contentCategoryIsEqual}
                  filteredTags={filteredTags}
                  setTagSearchString={setTagSearchString}
                  tagSearchString={tagSearchString}
                  toggleCategory={toggleCategory}
                />
              )}
              {!showTagArea && categoryFilterState.length > 0 && (
                <Box>
                  <Text mt={4} mb={2} fontWeight={600} color={"darkBlue.500"} fontSize={"sm"}>
                    Ausgewählte Tags
                  </Text>
                  <Flex flexWrap={"wrap"} gap={4}>
                    {categoryFilterState.map((categoryFilter, index) => (
                      <Tag
                        colorScheme={categoryFilterState.includes(categoryFilter) ? "blue" : "facebook"}
                        borderRadius={"3xl"}
                        bgColor={"darkBlue.500"}
                        color={"white"}
                        key={index}
                      >
                        {categoryFilter.name}
                      </Tag>
                    ))}
                  </Flex>
                </Box>
              )}
              <Divider my={2} />
            </>
          )}
          <Box my={4}>
            <Pagination
              totalPages={libraryTotalPages}
              setEntityLimitPerPage={handleSetLimit}
              entityLimitPerPage={libraryLimitPerPage}
              currentPage={libraryCurrentPage}
              onPageChange={page => onPageChange(page)}
            />
          </Box>
          <SimpleGrid columns={{ base: 2, sm: 2, md: 3, lg: 4, xl: 5, "2xl": 6 }} gap={{ base: "8", lg: "12" }} mt={6}>
            {paginatedContent.map((c, index) => (
              <ContentCard key={index} content={c} />
            ))}
          </SimpleGrid>
          {filteredContent.length <= 0 && atleastOneFilterIsSelected && <NoContentFound></NoContentFound>}
          <Pagination
            totalPages={libraryTotalPages}
            setEntityLimitPerPage={setLibraryLimitPerPage}
            entityLimitPerPage={libraryLimitPerPage}
            currentPage={libraryCurrentPage}
            onPageChange={page => onPageChange(page)}
          />
        </Box>
      </Box>
    </>
  );
};
