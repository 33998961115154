import { Box, Flex, Tag, Text, useBreakpointValue } from "@chakra-ui/react";
import { CubeIcon, GlobeAltIcon, VideoCameraIcon } from "@heroicons/react/solid";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { BasicObject } from "../../../../constants";
import { IContent } from "../../../../models/content";
import TruncateString from "../../../ui/typography/truncate-string";

interface IHeroAreaElementProps {
  displayContent: IContent;
  enableAutoRotation: boolean;
  activeElementIndex: number;
  setActiveElementIndex: Dispatch<SetStateAction<number>>;
  index: number;
}

export const HeroAreaElement = ({
  displayContent,
  index,
  setActiveElementIndex,
  activeElementIndex,
  enableAutoRotation
}: IHeroAreaElementProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const elementWidht = useBreakpointValue(
    isHovered
      ? { base: "65%", sm: "65%", md: "60%", lg: "45%", xl: "35%" }
      : { base: "35%", sm: "35%", md: "35%", lg: "25%", xl: "25%" }
  );
  const previewFilePath = useMemo(() => {
    if (displayContent) {
      return displayContent.previewFiles![0].file;
    }
    return "";
  }, [displayContent]);

  const renderContentTypeIcon = (content: IContent) => {
    let icon = undefined;
    if (content.contentType === "app") {
      icon = <CubeIcon className="w-4 h-4" />;
    }
    if (content.contentType === "video") {
      icon = <VideoCameraIcon className="w-4 h-4" />;
    }
    if (content.contentType === "webApp") {
      icon = <GlobeAltIcon className="w-4 h-4" />;
    }

    return (
      <Flex bgColor={"white"} borderRadius="100%" justify={"center"} align={"center"} w={6} h={6}>
        {icon}
      </Flex>
    );
  };

  useEffect(() => {
    if (enableAutoRotation) {
      if (activeElementIndex == index) {
        setIsHovered(true);
      } else {
        setIsHovered(false);
      }
    } else {
      if (activeElementIndex != index) {
        setIsHovered(false);
      }
    }
  }, [enableAutoRotation, activeElementIndex]);

  return (
    <Flex
      overflow={"hidden"}
      transition={"all 1.5s"}
      bgImage={previewFilePath}
      as="a"
      href={`/library/${displayContent.id}`}
      justify={"center"}
      align={"center"}
      filter={isHovered ? "none" : "grayscale(70%)"}
      backgroundPosition={"center"}
      backgroundSize={"cover"}
      backgroundRepeat="no-repeat"
      onMouseEnter={() => {
        setIsHovered(true);
        setActiveElementIndex(index);
      }}
      onMouseLeave={() => setIsHovered(false)}
      w={elementWidht}
    >
      <Box
        transition={"all 1.5s"}
        opacity={isHovered ? "100" : "0"}
        mt={"auto"}
        px={2}
        pt={4}
        pb={2}
        pos={"relative"}
        h={{ md: "60%", lg: "45%" }}
        w={"100%"}
        bgColor="#000000a1"
      >
        <Flex flexDir={"column"} align={"flex-start"} h="100%" gap={4}>
          <Flex transition={"all 4s"} opacity={isHovered ? "100" : "0"} align={"center"} gap={2}>
            {renderContentTypeIcon(displayContent)}
            <Text fontSize={["xs", "sm", "sm", "sm", "md"]} ml={0} color="white" fontWeight={600} fontFamily="Bitter">
              <TruncateString length={40} targetString={displayContent.title!} />
            </Text>
          </Flex>

          <Flex gap={2} display={{ base: "none", sm: "flex" }}>
            {displayContent.contentCategories.slice(0, 2).map((item: BasicObject) => {
              return (
                <Tag bgColor={"darkBlue.500"} key={item.id} size="sm" borderRadius={"xl"} color={"white"}>
                  {item.name}
                </Tag>
              );
            })}
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
