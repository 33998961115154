import React, { useEffect, useMemo, useState } from "react";

import { DefaultLayout } from "../../src/layouts/defaultLayout";
import { IContent, ILibraryContent } from "../../src/models/content";
import { ApiLibraryContentService } from "../../src/services/http";
import { getPaginatedData, getPaginatedDataGenerator } from "../../src/utils/http";
import { ContentList } from "../../src/components/content/components/list/contentList";
import { daysDiffrenceToToday } from "../../src/utils/helper";
import { ApiMSBContentService } from "../../src/services/http/apiMSBContentService";
import { useAuth } from "../../src/provider/authentication";
import { ApiProductService } from "../../src/services/http/apiProductService";
import { IProduct } from "../../src/models/product";

export default function Library(): React.ReactElement {
  const { user, isAuthenticated, isMSBUser, isSocialUser } = useAuth();

  const [availableProducts, setAvailableProducts] = useState<Array<IProduct>>([]);
  const userOrgaID = user?.firstOrganisation?.id;
  const {} = useAuth();
  const [content, setContent] = useState<IContent[]>([]);
  const [msbContent, setMsbContent] = useState<IContent[]>([]);
  const [isLoadingContent, setIsLoadingContent] = useState(true);
  const [finishedLoadingAllContent, setFinishedLoadingAllContent] = useState(false);
  const hasMSBContentAcess = isMSBUser || isSocialUser;

  useEffect(() => {
    const fetchProducts = () => {
      ApiProductService.fetchAllProductsForOrganisation().then(res => {
        if (res.status === 200) {
          if (isSocialUser) {
            setAvailableProducts(
              res.data.results.sort((a: IProduct, b: IProduct) => {
                return a.name.localeCompare(b.name);
              })
            );
          } else {
            setAvailableProducts(
              res.data.results
                .filter((product: IProduct) => product.owner.id === userOrgaID)
                .sort((a: IProduct, b: IProduct) => {
                  return a.name.localeCompare(b.name);
                })
            );
          }
        }
      });
    };

    const fetchLibraryData = async () => {
      setIsLoadingContent(true);
      setContent([]);
      const generator = getPaginatedDataGenerator<ILibraryContent>(ApiLibraryContentService.fetchAllContent);
      let newContent: ILibraryContent[] = []; // Temporary array to collect all fetched data
      for await (const content of generator) {
        // Process each item
        const processedContent = content.map(item => {
          const dayDifference = daysDiffrenceToToday(item.createdAt);
          return {
            ...item,
            isNew: dayDifference >= 0 && dayDifference <= 28,
            diffInDays: dayDifference
          };
        });

        // Append processed content to state
        newContent = [...newContent, ...processedContent];
        setContent(currentContent => [...currentContent, ...processedContent]);
        setIsLoadingContent(false);
      }
      setFinishedLoadingAllContent(true);
    };

    const fetchMSBContent = () => {
      getPaginatedData<IContent>(ApiMSBContentService.fetchAllContent).then((content: IContent[]) => {
        for (let i = 0; i < content.length; i++) {
          let dayDiffrence = daysDiffrenceToToday(content[i].createdAt);
          if (dayDiffrence >= 0 && dayDiffrence <= 28) {
            content[i].isNew = true;
          }
          content[i].diffInDays = dayDiffrence;
        }

        setMsbContent(content);
      });
    };

    if (content.length == 0) {
      fetchLibraryData();
    }
    if (hasMSBContentAcess) {
      fetchMSBContent();
    }
    if (isAuthenticated) {
      fetchProducts();
    }
  }, [hasMSBContentAcess, isAuthenticated]);

  const uniqueDeviceTypeIDs: string[] = useMemo(() => {
    const getUniqueDeviceTypes = (interalProducts: IProduct[]): string[] => {
      const deviceTypes = new Set<any>(); // Set to store unique device types

      interalProducts.forEach(obj => {
        obj.devices.forEach(device => {
          deviceTypes.add(String(device.deviceType));
        });
      });

      return Array.from(deviceTypes); // Convert the set back to an array
    };

    if (isAuthenticated && availableProducts) {
      return getUniqueDeviceTypes(availableProducts);
    }

    return [];
  }, [availableProducts, isAuthenticated]);

  return (
    <DefaultLayout>
      <ContentList
        hasMSBContentAcess={hasMSBContentAcess}
        showHeroArea
        uniqueDeviceTypeIDs={uniqueDeviceTypeIDs}
        isLoadingContent={isLoadingContent}
        finishedLoadingAllContent={finishedLoadingAllContent}
        content={content}
        msbContent={msbContent}
        renderFilter={true}
      />
    </DefaultLayout>
  );
}
