import React, { createContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useAuth } from "./authentication";

interface ISupportBuddyContextStates {
  currentTargetId: number | undefined;
  currentTargetTitle: string | undefined;
}

type RouteConfig = {
  match: (pathname: string) => boolean; // Function to match the route
  id: number | ((pathname: string) => number); // Support for dynamic ID
  title: string | ((pathname: string) => string); // Support for dynamic title
};
type RouteData = { id: number; title: string };

/**
 * Creates the context.
 *
 */
const SupportBuddyContext = createContext<ISupportBuddyContextStates>({ currentTargetId: undefined, currentTargetTitle: "" });

/**
 * Wrapper around the application to provide route based data.
 *
 */
export const SupportBuddyProvider: React.FC<React.PropsWithChildren> = props => {
  const router = useRouter();
  const { user, isKMZUser } = useAuth();
  const [currentTargetId, setCurrentTargetId] = useState<number | undefined>();
  const [currentTargetTitle, setCurrentTargetTitle] = useState<string | undefined>();
  const isAdminUser = user && user.isAdministratorOfCurrentOrganisation();
  const routeConfig: RouteConfig[] = [
    {
      match: (pathname: string) => pathname === "/library",
      id: 0,
      title: "Mediathek"
    },
    {
      match: (pathname: string) => pathname.startsWith("/library/new"),
      id: 3,
      title: "Inhalt erstellen"
    },
    {
      match: (pathname: string) => pathname.startsWith("/library/"),

      id: (pathname: string) => {
        const dynamicPart = pathname.split("/")[3]; // extract the dynamic part
        if (dynamicPart != "edit") {
          if (isAdminUser) {
            return 1;
          }
          return 2;
        } else {
          if (isAdminUser) {
            return 4;
          }
          return 5;
        }
      },
      title: (pathname: string) => {
        const dynamicPart = pathname.split("/")[3]; // extract last part of the url
        return dynamicPart === "edit" ? "Inhalt bearbeiten" : "Mediathek - Details";
      }
    },

    {
      match: (pathname: string) => pathname.startsWith("/products/devices"),
      id: 9,
      title: "Produktverwaltung - VR-Brillen"
    },
    {
      match: (pathname: string) => pathname === "/products",
      id: () => {
        if (isAdminUser) {
          return isKMZUser ? 7 : 6;
        }
        return 8;
      },
      title: "Produktverwaltung - Allgemein"
    },
    {
      match: (pathname: string) => pathname === "/configurations",
      id: 10,
      title: "Produktverwaltung - Wiedergabelisten"
    },
    {
      match: (pathname: string) => pathname.startsWith("/configurations"),
      id: 11,
      title: "Wiedergabelisten - Details/Bearbeiten"
    },
    {
      match: (pathname: string) => pathname === "/moderate" || pathname.startsWith("/moderate"),
      id: 12,
      title: "Moderieren"
    },
    {
      match: (pathname: string) => pathname === "/administration",
      id: 13,
      title: "Adminbereich"
    },
    {
      match: (pathname: string) => pathname === "/support",
      id: 14,
      title: "Support - Hilfeseite"
    },
    {
      match: (pathname: string) => pathname.startsWith("/groups/new"),
      id: 16,
      title: "Gruppierung erstellen"
    },
    {
      match: (pathname: string) => pathname === "/groups",
      id: 15,
      title: "Gruppierungen"
    },
    {
      match: (pathname: string) => pathname.startsWith("/groups/"),

      id: (pathname: string) => {
        const dynamicPart = pathname.split("/")[3]; // extract the dynamic part
        if (dynamicPart === "edit") {
          return 17;
        } else {
          return 16; // Example dynamic logic
        }
      },
      title: (pathname: string) => {
        const dynamicPart = pathname.split("/")[3]; // extract the dynamic part
        return dynamicPart === "edit" ? "Gruppierung bearbeiten" : "Gruppierung - Details"; // Example dynamic logic
      }
    },
    {
      match: (pathname: string) => pathname === "/",
      id: 18,
      title: "Portal"
    }
  ];

  const getRouteData = (pathname: string): RouteData | undefined => {
    // Find the first route that matches the current pathname
    const matchedRoute = routeConfig.find(route => route.match(pathname));

    if (!matchedRoute) return undefined;

    const id = typeof matchedRoute.id === "function" ? matchedRoute.id(pathname) : matchedRoute.id;
    const title = typeof matchedRoute.title === "function" ? matchedRoute.title(pathname) : matchedRoute.title;

    return { id, title };
  };

  useEffect(() => {
    const routeData = getRouteData(router.pathname);

    if (routeData) {
      setCurrentTargetId(routeData.id);
      setCurrentTargetTitle(routeData.title);
    } else {
      setCurrentTargetId(undefined);
      setCurrentTargetTitle(undefined);
    }
  }, [router]);

  return (
    <SupportBuddyContext.Provider value={{ currentTargetId, currentTargetTitle }}>{props.children}</SupportBuddyContext.Provider>
  );
};

export function useSupportBuddy(): ISupportBuddyContextStates {
  const context = React.useContext(SupportBuddyContext);
  if (context === undefined) {
    throw new Error("useSupportBuddy must be used within a SupportBuddyContext.");
  }

  return context;
}
