import { WarningIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import { SVGProps } from "react";

interface IButtonIconProps {
  icon: any;
  intialValue?: number;
}

export const ResponsiveButtonIcon: React.FC<IButtonIconProps> = props => {
  const returnFittingSizes = () => {
    switch (props.intialValue) {
      case 5:
        return [3, 3, 4, 5];
      case 4:
        return [3, 3, 4, 4];
      case 3:
        return [3, 3, 4, 4];
      default:
        return [3, 3, 4, 5];
    }
  };

  return (
    <Box w={returnFittingSizes()} h={returnFittingSizes()}>
      <props.icon />
    </Box>
  );
};
