import React, { useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";

import { Menu } from "./shared/menu";
import PlaybackBar from "../components/ui/playback/playback-bar";
import { Size } from "../constants";
import { useRouter } from "next/router";
import Footer from "./shared/footer";
import SupportBuddy from "../components/support/components/support-buddy";
import { useSupportBuddy } from "../provider/supportBuddyProvider";

export const DefaultLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const [isMarketplace, setIsMarketplace] = useState(false);
  const { currentTargetId } = useSupportBuddy();

  useEffect(() => {
    if (router.pathname.includes("/marketplace")) {
      setIsMarketplace(true);
    } else {
      setIsMarketplace(false);
    }
  }, [router]);

  return (
    <>
      <Flex w={"full"} height={"full"} flexDirection={"column"} pos="relative">
        <Menu />
        <SupportBuddy />
        <Box
          w={"full"}
          borderBottomRadius="3xl"
          flexGrow={1}
          pb={"12"}
          mt={Size.NAVBAR_HEIGHT}
          bgColor={isMarketplace ? "#2d465f" : "white"}
        >
          {children}
        </Box>
        <Box h={"1rem"} w={"100%"} bgColor="darkBlue.500">
          <Box h={"1rem"} borderBottomRadius={"3xl"} w={"100%"} bgColor="white"></Box>
        </Box>
        <Footer isMarketplace={isMarketplace} />
        <PlaybackBar />
      </Flex>
    </>
  );
};
