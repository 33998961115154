import type { AxiosResponse } from "axios";

import { IDevice, IDeviceType } from "../../models/device";
import { CloudCommand } from "../../constants";
import { dispatch } from "./http";

export class ApiDeviceService {
  public static fetchAllDevices(pageParam: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: "/api/v2/mdm/devices/",
      params: pageParam
    });
  }
  public static fetchAllDeviceTypes(): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: "/api/v2/mdm/device-type/"
    });
  }

  public static fetchSingleDevice(serialNumber: string): Promise<AxiosResponse<IDevice>> {
    return dispatch({
      method: "GET",
      url: `/api/v2/mdm/devices/${serialNumber}/`
    });
  }

  public static executeCommand(
    serialNumber: string,
    command: CloudCommand,
    contentUuid?: string,
    packageName?: string,
    guidedModeState?: boolean,
    manufacturerModeState?: boolean
  ): Promise<AxiosResponse> {
    const requestData: any = {
      command,
      extras: {
        ...(contentUuid && { contentUuid }),
        ...(packageName && { packageName }),
        ...{ guidedModeState },
        ...{ manufacturerModeState }
      }
    };

    return dispatch({
      method: "POST",
      url: `/api/v1/mdm/devices/${serialNumber}/command/`,
      data: requestData
    });
  }
}
