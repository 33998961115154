import { Progress, HStack, Text, Box } from "@chakra-ui/react";
import { secondsToTimeString } from "../../../utils/helper";
import { useMemo } from "react";

interface IVideoProgressBarProps {
  currentTime: number;
  duration: number;
}

/**
 * Displays a playback video bar.
 * @param currentTime Current time in seconds
 * @param maxTime Max time in seconds
 * @constructor
 */
export default function VideoProgressBar({ currentTime, duration }: IVideoProgressBarProps) {
  const progressValue = useMemo(() => {
    return (currentTime / duration) * 100;
  }, [currentTime, duration]);

  return (
    <HStack spacing={"4"} w={"full"}>
      <Text fontSize={"md"} fontWeight={600}>
        {secondsToTimeString(currentTime)}
      </Text>
      <Progress color={"pink"} border={"15px"} h={"10px"} w={"full"} value={progressValue} />
      <Text fontSize={"md"} fontWeight={600}>
        {secondsToTimeString(duration)}
      </Text>
    </HStack>
  );
}
