import { Box, Flex, Input, Tag } from "@chakra-ui/react";
import { useState } from "react";

import { IContentCategory } from "../../../../../models/contentCategory";

interface IContentListTagSelectionProps {
  categoryFilterState: IContentCategory[];
  contentCategoryIsEqual: any;
  filteredTags: {
    name: any;
    counter: number;
  }[];

  setTagSearchString: any;
  tagSearchString: string;
  toggleCategory: any;
}

export const ContentListTagSelection = ({
  categoryFilterState,
  contentCategoryIsEqual,
  filteredTags,

  setTagSearchString,

  tagSearchString,
  toggleCategory
}: IContentListTagSelectionProps) => {
  const defaultBoxSize = { base: "120px", sm: "140px", lg: "100px" };
  const [boxSize, setBoxSize] = useState(defaultBoxSize);
  const enlargeSelecton = () => {
    setBoxSize({ base: "250px", sm: "350px", lg: "350px" });
  };
  return (
    <Box
      w={"full"}
      my={4}
      onMouseEnter={() => setBoxSize({ base: "250px", sm: "350px", lg: "350px" })}
      onMouseLeave={() => {
        setBoxSize(defaultBoxSize);
      }}
      mx={0}
      shadow="sm"
      border="2px solid"
      overflow={"hidden"}
      borderColor={"darkBlue.500"}
      pos={"relative"}
      borderRadius={"3xl"}
    >
      <Flex
        transition={"all 0.75s ease-in-out"}
        flexWrap={"wrap"}
        bg={"whiteAlpha.100"}
        gap={4}
        p={4}
        boxShadow={"inner"}
        maxH={boxSize}
        overflowY={"scroll"}
      >
        <>
          <Box w={"full"}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
              <Input
                bgColor={"white"}
                value={tagSearchString}
                onFocus={enlargeSelecton}
                size="sm"
                borderRadius={"3xl"}
                mr={2}
                w={"20rem"}
                placeholder={"Nach Tags suchen..."}
                type={"text"}
                onInput={(e: any) => {
                  setTagSearchString(e.target.value);
                }}
              />
            </Flex>
          </Box>
          {filteredTags.length === 0 && <>Keine Tags zu dieser Suche</>}
          {filteredTags.map((categoryFilter, index) => (
            <Tag
              key={index}
              borderRadius={"3xl"}
              colorScheme={
                categoryFilterState.some(obj => contentCategoryIsEqual(obj.name, categoryFilter.name)) ? "darkBlue" : "facebook"
              }
              color={
                categoryFilterState.some(obj => contentCategoryIsEqual(obj.name, categoryFilter.name)) ? "white" : "gray.700"
              }
              size={{ base: "sm", sm: "sm", md: "md" }}
              cursor={"pointer"}
              onClick={() => {
                toggleCategory(categoryFilter);
              }}
            >
              {categoryFilter.name}
              <Box mx="2" fontWeight={600}>
                {categoryFilter.counter}
              </Box>
            </Tag>
          ))}
        </>
      </Flex>
    </Box>
  );
};
